import '@fortawesome/fontawesome-pro/js/all.min';
import 'slick-carousel';

import './site/sliders';
import './site/logos-banner';
import './site/navigation';
import './site/locations-map';
import './site/discounts-map';

import * as ADMIN_CONSTANTS from './config/wombat';

window.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

