// region Shared Slider Configs
// Slick default doesn't handle multiple navs well, especially with breakpoints and we need separate ones for desktop and mobile
function attachArrows($slider, $nav) {
	$nav.each(function () {
		const $prevArrow = $(`<button class="slick-arrow slick-prev"><i class="fal fa-long-arrow-left" aria-hidden="true"></i> Previous</button>`);
		const $nextArrow = $(`<button class="slick-arrow slick-next"><i class="fal fa-long-arrow-right" aria-hidden="true"></i> Next</button>`);

		$(this).append($prevArrow);
		$(this).append($nextArrow);

		$prevArrow.on('click', () => $slider.slick('slickPrev'));
		$nextArrow.on('click', () => $slider.slick('slickNext'));
	});
}

// endregion

$('.gallery').each(function () {
	const $gallery = $(this);
	const $slider = $gallery.find('.gallery-slider');

	$slider.on('beforeChange', function () {
		$slider.find('.slick-slide-prev').removeClass('slick-slide-prev');
		$slider.find('.slick-slide-next').removeClass('slick-slide-next');
	});

	$slider.on('init reInit afterChange', function () {
		const $current = $slider.find('.slick-slide.slick-current');

		$current.prev('.slick-slide').addClass('slick-slide-prev');
		$current.next('.slick-slide').addClass('slick-slide-next');
	});

	$slider.on('click', '.slick-slide-prev', function () {
		$slider.slick('slickPrev');
	});

	$slider.on('click', '.slick-slide-next', function () {
		$slider.slick('slickNext');
	});

	$slider.slick({
		autoplay: true,
		speed: 700,
		pauseOnHover: false,
		autoplaySpeed: 5000,
		arrows: false,
		dots: false,
		infinite: true
	});
});

$('.taleo').each(function () {
	const $taleo = $(this);
	const $slider = $taleo.find('.taleo-slider');
	const $nav = $taleo.closest('.section').find('.section-arrows');

	$slider.slick({
		infinite: false,
		mobileFirst: true,
		slidesToShow: 0.9,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2.5
				}
			}
		]
	});

	attachArrows($slider, $nav);
});
