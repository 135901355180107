import { isEditMode } from './helpers';

const getModal = key => $(`.modal[data-modal=${key}]`).first();

export function openModal(key) {
	const $modal = getModal(key);

	if (!$modal.length || isEditMode) return;

	$modal.addClass('visible');
}

export function closeModal(key) {
	const $modal = getModal(key);

	if (!$modal.length) return;

	$modal.removeClass('visible');
}

$(function () {
	$('.modal').each(function () {
		const $modal = $(this);
		const key = $modal.data('modal');

		$(this).on('click', '.modal-close', function () {
			closeModal(key);
		});
	});
});
