$(function () {
	var isMobile = $(window).width() < 1025;

	if (!isMobile) return;

	$('.logo-banner').each(function () {
		const $container = $(this).find('.logo-banner-container');
		const $images = $container.find('img');

		$images.each(function () {
			$(this).width($(this).width());
			$(this).height('auto');
		});

		$container.slick({
			slidesToShow: 1,
			variableWidth: true,
			autoplay: true,
			autoplaySpeed: 2000,
			arrows: false
		});
	});
});
