import { StatePicker } from './statepicker';

class LocationsMap extends StatePicker {
	constructor($widget) {
		super($widget);

		import(`@default/data/locations/${this.key}.json`).then(({ default: locations }) => {
			this.locations = locations;
			this.activeStates = Object.keys(this.locations);
			this.init();
		});
	}

	buildUIData() {
		const ui = this.ui;

		ui.$properties = ui.$widget.find('.locations-properties');
		ui.$propertiesHeader = ui.$properties.find('.locations-properties-header');
		ui.$propertiesList = ui.$properties.find('.locations-properties-list');

		super.buildUIData();
	}

	removeColumnClass() {
		const { $properties } = this.ui;
		for (let i = 2; i < 6; i++) {
			$properties.removeClass(`locations-properties-${i}-columns`);
		}
	}

	setColumnsClass(length) {
		const { $properties } = this.ui;
		const constrain = (x, min, max) => Math.max(min, Math.min(x, max));
		const columnsCount = constrain(Math.ceil(length / 6), 1, 5);

		$properties.addClass(`locations-properties-${columnsCount}-columns`);
	}

	onSelected(selection) {
		this.updatePropertiesList(selection);
	}

	onDeselected() {
		const { $properties, $propertiesHeader, $propertiesList } = this.ui;

		$properties.removeClass('show');
		$propertiesHeader.find('h3').text(``);
		$propertiesList.html('');
	}

	updatePropertiesList(selection) {
		const properties = this.locations[selection];

		const { $properties, $propertiesHeader, $propertiesList } = this.ui;

		$properties.addClass('show');
		$propertiesHeader.find('h3').text(`${selection} ${this.type}`);
		$propertiesList.html('');

		properties.forEach(({ name, city, link = '' }) => {
			const $item = $(` <li class="locations-properties-item" />`);

			const $content = link ? $(`<a href="${link}" target="_blank" />`) : $('<div />');
			$content.append(`<h5>${name}</h5>`);
			$content.append(`<p>${city}</p>`);

			$item.append($content);

			$propertiesList.append($item);
		});

		this.removeColumnClass();
		this.setColumnsClass(properties.length);

		this.scrollToProperties();
	}

	scrollToProperties() {
		const { $properties } = this.ui;

		$('html, body').animate({ scrollTop: $properties.offset().top - 16 });
	}

	get type() {
		const { $widget } = this.ui;
		return $widget.data('property-type');
	}

	get key() {
		return this.type.toLowerCase();
	}
}

$(function () {
	$('.locations').each(function () {
		const $widget = $(this);

		new LocationsMap($widget);
	});
});
