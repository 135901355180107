import { StatePicker } from './statepicker';
import { openModal } from './modal';

class DiscountsMap extends StatePicker {
	constructor($widget) {
		super($widget);

		import(`@default/data/discount-rates.json`).then(({ default: locations }) => {
			this.locations = locations;
			this.activeStates = this.locations
				.map(l => l.state) // Get state property from location object
				.filter((x, i, a) => a.indexOf(x) === i); // Filter unique state names

			this.init();

			this.buildListing();
		});
	}

	buildUIData() {
		const ui = this.ui;

		ui.$actions = ui.$widget.find('.discounts-actions');
		ui.$properties = ui.$widget.find('.discounts-properties');
		ui.$propertiesHeader = ui.$properties.find('.discounts-properties-header');
		ui.$propertiesList = ui.$properties.find('.discounts-properties-list');

		super.buildUIData();
	}

	attachEvents() {
		const { $actions } = this.ui;
		super.attachEvents();

		const self = this;

		$actions.on('click', '[data-action]', function (e) {
			e.preventDefault();
			const action = $(this).data('action');

			switch (action) {
			case 'all':
				self.select('all');
				break;
			case 'instructions':
				openModal('discount-instructions');
				break;
			default:
				break;
			}
		});
	}

	buildListing() {
		this.locations.forEach(loc => {
			this.createListingItem(loc);
		});
	}

	onSelected(selection) {
		const { $properties } = this.ui;

		$properties.addClass('visible');

		if (selection === 'all') {
			$properties.find('.discounts-properties-item').addClass('visible');
		} else {
			$properties.find('.discounts-properties-item').removeClass('visible');
			$properties.find(`.discounts-properties-item[data-state="${selection}"]`).addClass('visible');
		}

		this.scrollToProperties();
	}

	scrollToProperties() {
		const { $properties } = this.ui;

		$('html, body').animate({ scrollTop: $properties.offset().top - 16 });
	}

	createListingItem(item) {
		const { $propertiesList } = this.ui;
		const itemHtml = `
		<div class="discounts-properties-item" data-state="${item.state}">
		  <div class="copy-media media-left">
		    <div class="copy-media-media">
		      <div class="image-frame-1">
	          <img src="${item.image}" alt="${item.name}" />
		      </div>
		    </div>
		    <div class="copy-media-copy">
		      <h3>${item.name}</h3>
		      <p>${item.location}</p>
		      <p>${item.details}</p>
		    </div>
		  </div>
		</div>
		`;

		const formHtml = `
		<form method="post" class="form form-inline discounts-properties-selector">
      <div class="form-group form-group-select">
        <label class="form-label">Select Marcus Rate</label>
        <select class="form__select" name="rate-option">
          ${item.associateRate ? `<option value="${item.associateRate}">Associate Rate</option>` : ''}
          ${item.familyRate ? `<option value="${item.familyRate}">Friends & Family Rate</option>` : ''}
				 ${item.businessRate ? `<option value="${item.businessRate}">Business Rate</option>` : ''}
				</select>
      </div>
      <button class="button button-2" type="submit">Submit</button>
		</form>
		`;

		const $element = $(itemHtml);

		if (!item.isClosed) {
			const $form = $(formHtml);

			$form.on('submit', function (e) {
				e.preventDefault();
				const rateUrl = $form.find('[name="rate-option"]').val();
				window.open(rateUrl);
			});

			$element.find('.copy-media-copy').append($form);
		}
		$propertiesList.append($element);
	}

	getTooltipContent(name) {
		let content = `<h4>${name}</h4>`;

		const properties = this.locations.filter(l => l.state === name);

		properties.forEach(prop => {
			content += `<p>${prop.name}</p>`;
		});

		return content;
	}
}

$(function () {
	let $discounts = $('.discounts');
	if ($discounts.length) {
		openModal('discount-terms');
		$discounts.each(function () {
			const $widget = $(this);

			new DiscountsMap($widget);
		});
	}
});
