export const EDITOR_FORMATTING_OPTIONS = [
	{ text: 'Button 1', value: 'a.button button-1' },
	{ text: 'Button 2', value: 'a.button button-2' }
];

export const EDITOR_TOOLS = [
	'bold',
	'italic',
	'underline',
	'insertUnorderedList',
	'insertOrderedList',
	'createLink',
	'separator',
	'justifyLeft',
	'justifyCenter',
	'justifyRight',
	'justifyFull',
	'separator',
	'viewHtml'
];

export const EDITOR_STYLES_PATH = '/default/dist/css/admin.css';
