export class Tooltip {
	constructor(basename = 'tooltip') {
		this.basename = basename;
		this.buildUIData();
	}

	buildUIData() {
		let $tooltip = $(`#${this.basename}`);
		if (!$tooltip.length) {
			$tooltip = this.createTooltip();
		}

		const $content = $tooltip.find(`.${this.basename}-content`);

		this.ui = {
			$tooltip,
			$content
		};
	}

	createTooltip() {
		const $tooltip = $(`<div id="${this.basename}" class="${this.basename}" />`);

		const $content = $(`<div class="${this.basename}-content" />`);
		$tooltip.append($content);

		$('body').append($tooltip);

		$(document).on('mousemove', (e) => {
			const { clientX, clientY } = e;

			$tooltip.css({
				left: clientX,
				top: clientY
			});
		});

		return $tooltip;
	}

	setContent(content) {
		const { $content } = this.ui;

		$content.html(content);
	}

	show(content) {
		const { $tooltip } = this.ui;
		if (content) this.setContent(content);

		$tooltip.addClass('visible');
	}

	hide() {
		const { $tooltip } = this.ui;
		$tooltip.removeClass('visible');
	}
}
