import { Tooltip } from './tooltip';

export class StatePicker {
	constructor($widget) {
		this.selected = '';
		this.activeStates = [];
		this.tooltip = new Tooltip();
		this.ui = this.ui || { $widget };
	}

	init() {
		this.buildUIData();
		this.populateMap();
		this.attachEvents();
	}

	buildUIData() {
		const ui = this.ui;

		ui.$picker = ui.$widget.find('.statepicker');
		ui.$map = ui.$picker.find('.statepicker-map');
		ui.$selector = ui.$picker.find('.statepicker-select select');
		ui.$states = ui.$map.find('g.state');
	}

	getActiveStates() {
		const { $states } = this.ui;
		return $states.filter('.active');
	}

	populateMap() {
		const { $states, $selector } = this.ui;

		this.activeStates.sort().forEach(state => {
			$states.filter(`[data-state="${state}"]`).addClass('active');
			$selector.append($(`<option value="${state}">${state}</option>`));
		});
	}

	attachEvents() {
		const { $widget, $map, $selector } = this.ui;
		const self = this;

		$map.on('click', 'g.state.active', function (e) {
			e.preventDefault();
			self.select($(this).data('state'));
		});

		$selector.on('change', function (e) {
			e.preventDefault();
			self.select($(this).val());
		});

		$map.on('mouseover', function (e) {
			const $state = $(e.target).closest('[data-state]');
			self.handleHover($state);
		});

		$map.on('mouseleave', function () {
			self.tooltip.hide();
		});
	}

	handleHover($state) {
		if (!$state.length || !$state.hasClass('active')) {
			this.tooltip.hide();
			return;
		}

		this.tooltip.show(this.getTooltipContent($state.data('state'), $state));
	}

	select(val) {
		const { $selector } = this.ui;
		this.selected = val;

		const $active = this.getActiveStates();

		$active.removeClass('selected');

		if (val) {
			$active.filter(`[data-state="${val}"]`).addClass('selected');
			$selector.val(val);

			if ('onSelected' in this) {
				this.onSelected(val);
			}
		} else {
			$selector.val('');

			if ('onDeselected' in this) {
				this.onDeselected(val);
			}
		}
	}

	deselect() {
		this.select('');
	}

	getTooltipContent(name) {
		return name;
	}
}
