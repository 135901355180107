$(function () {
	const $toggle = $('.nav__toggle');
	const $header = $('.header');
	const $navList = $('.nav__list--primary');

	$toggle.on('click', e => {
		e.preventDefault();
		$header.toggleClass('nav-open').trigger('nav.toggle');
	});

	$.fn.setup_navigation = function (settings) {

		settings = jQuery.extend({
			menuHoverClass: 'hovered'
		}, settings);

		if ($(window).width() < 1025) {
			$('.nav__item--primary.hasChild').each(function () {
				$(this).addClass(settings.menuHoverClass);

				$(this).find('.nav__list').each(function () {
					let height = $(this).height();
					$(this).css('max-height', height);
				});

				$(this).removeClass(settings.menuHoverClass);
			});
		}

		// Add ARIA role to menubar and ProductMenu items
		$(this).attr('role', 'menubar').find('li').attr('role', 'menuitem');
		const top_level_links = $(this).find('.nav__link--primary');

		function toggleNav(itemElem, force) {
			let isOpen = $(itemElem).hasClass(settings.menuHoverClass);
			if (!isOpen || force === 'open') {
				$(itemElem).closest('ul')
					.attr('aria-hidden', 'false')
					.find('.' + settings.menuHoverClass)
					.removeClass(settings.menuHoverClass)
					.children('ul')
					.attr('aria-hidden', 'true')
					.find('a')
					.attr('tabIndex', -1);

				$(itemElem)
					.addClass(settings.menuHoverClass)
					.children('ul')
					.attr('aria-hidden', 'false')
					.find('a').attr('tabIndex', 0);
			} else if (isOpen || force === 'close') {
				$(itemElem)
					.removeClass(settings.menuHoverClass)
					.children('ul')
					.attr('aria-hidden', 'true')
					.find('a')
					.attr('tabIndex', -1);
			}
		}

		if ($(window).width() >= 1200) {
			$(top_level_links).on('mouseover focus', function () {
				toggleNav($(this).closest('.nav__item--primary'), 'open');
			});

			$(this).find('.nav__item--primary').on('mouseleave', function () {
				toggleNav($(this), 'close');
			});

			$(document).on('click', function () {
				toggleNav($('.' + settings.menuHoverClass), 'close');
			});
		} else {
			$('.nav__link__child-toggle').on('click', function (e) {
				e.preventDefault();
				toggleNav($(this).closest('.nav__item'));
			});
		}

		$(top_level_links).on('keydown', function (e) {
			const { keyCode } = e;

			const $childrenList = $(this).siblings('ul');
			const $navItem = $(this).parent('li');

			switch (keyCode) {
			case 37: // Left
				e.preventDefault();
				// This is the first item
				if (!$navItem.prev('li').length) {
					$(this).closest('ul').find('> li').last().children('a').first().focus();
				} else {
					$navItem.prev('li').children('a').first().focus();
				}
				break;
			case 38: // Up
				e.preventDefault();
				if ($childrenList.length > 0) {
					$childrenList
						.attr('aria-hidden', 'false')
						.addClass(settings.menuHoverClass)
						.find('a').attr('tabIndex', 0)
						.last().focus();
				}
				break;
			case 39: // Right
				e.preventDefault();
				// This is the last item
				if ($navItem.next('li').length === 0) {
					$(this).closest('ul').find('> li').first().children('a').first().focus();
				} else {
					$navItem.next('li').children('a').first().focus();
				}
				break;
			case 40: // Down
				e.preventDefault();
				if ($childrenList.length > 0) {
					$childrenList
						.attr('aria-hidden', 'false')
						.addClass(settings.menuHoverClass)
						.find('a').filter('[tabIndex=0]')
						.first().focus();
				}
				break;
			case 13: // Enter
			case 32: // Space
				// If submenu is hidden, open it
				e.preventDefault();
				toggleNav($navItem, 'open');

				$navItem
					.find('a').filter('[tabIndex=0]')
					.first().focus();
				break;
			case 27: // Esc
				e.preventDefault();
				toggleNav($navItem, 'close');
				break;
			default:
				$navItem.find('ul[aria-hidden=false] a').each(function () {
					if ($(this).text().substring(0, 1).toLowerCase() === e.key.toLowerCase()) {
						$(this).focus();
						return false;
					}
				});
			}
		});

		$('.nav__link--secondary').on('keydown', function (e) {
			const { keyCode } = e;
			const $parentLink = $(this).closest('ul').siblings('a');
			const $parentItem = $(this).closest('.nav__item--primary');
			const $navItem = $(this).parent('li');

			switch (keyCode) {
			case 37:
				e.preventDefault();
				$parentItem.prev('li').children('a').first().focus();
				break;
			case 38:
				e.preventDefault();
				// This is the first item
				if ($navItem.prev('li').length === 0) {
					$(this).closest('ul').find('> li').last().children('a').first().focus();
				} else {
					$navItem.prev('li').children('a').first().focus();
				}
				break;
			case 39:
				$parentItem.next('li').children('a').first().focus();
				break;
			case 40:
				e.preventDefault();
				// This is the last item
				if ($navItem.next('li').length === 0) {
					$(this).closest('ul').find('> li').first().children('a').first().focus();
				} else {
					$navItem.next('li').children('a').first().focus();
				}
				break;
			case 27:
				$parentLink.focus();
				toggleNav($parentItem, 'close');
				break;
			default:
				let found = false;
				$(this).parent('li').nextAll('li').children('a').each(function () {
					if ($(this).text().substring(0, 1).toLowerCase() === e.key.toLowerCase()) {
						$(this).focus();
						found = true;
						return false;
					}
				});

				if (!found) {
					$(this).parent('li').prevAll('li').children('a').each(function () {
						if ($(this).text().substring(0, 1).toLowerCase() === e.key.toLowerCase()) {
							$(this).focus();
							return false;
						}
					});
				}
			}
		});

		// Hide ProductMenu if click or focus occurs outside of navigation
		$(this).find('a').last().keydown(function (e) {
			if (e.keyCode === 9) {
				// If the user tabs out of the navigation hide all menus
				toggleNav($('.' + settings.menuHoverClass), 'close');
			}
		});

		$header.on('nav.toggle', () => toggleNav($('.' + settings.menuHoverClass), 'close'));
	};

	$navList.setup_navigation({
		menuHoverClass: 'child-open'
	});

	$('.subnav-select[name="href"]').on('change', function (e) {
		e.preventDefault();
		window.location.href = $(this).val();
	});
});
